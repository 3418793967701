import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";

export function Packages() {
  return (
    <Container id="packages">
      <h2>Packages</h2>
      <div className="packages">
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <div className="project-links"></div>
            </header>
            <div className="body">
              <h3>Basic</h3>
              <p>Push your business forward in a straightforward way</p>
              <svg
                width="100px"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 10 50 24"
                fill="none"
                stroke="#ffedc9 "
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M23 13H2v-2h21v2z"></path>
              </svg>
              <h3>from £250</h3>
              <ul>
                <li>
                  <svg
                    width="40"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    viewBox="0 -7 24 24"
                    fill="none"
                    stroke="#23ce6b "
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <title>Folder</title>
                    <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                  </svg>
                  Single Page of Content
                </li>
                <li>
                  <svg
                    width="40"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    viewBox="0 -7 24 24"
                    fill="none"
                    stroke="#23ce6b "
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <title>Folder</title>
                    <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                  </svg>
                  Website Design & Build
                </li>
                <li>
                  <svg
                    width="40"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    viewBox="0 -7 24 24"
                    fill="none"
                    stroke="#23ce6b "
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <title>Folder</title>
                    <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                  </svg>
                  Search Engine Optimisation
                </li>

                <li>
                  <svg
                    width="40"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    viewBox="0 -7 24 24"
                    fill="none"
                    stroke="#23ce6b "
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <title>Folder</title>
                    <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                  </svg>
                  Custom Domain Name
                </li>
                <li>
                  <svg
                    width="40"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    viewBox="0 -7 24 24"
                    fill="none"
                    stroke="#23ce6b "
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <title>Folder</title>
                    <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                  </svg>
                  1 Custom Email Address*
                </li>
                <li>
                  <svg
                    width="40"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    viewBox="0 -7 24 24"
                    fill="none"
                    stroke="#23ce6b "
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <title>Folder</title>
                    <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                  </svg>
                  SSL Certificate
                </li>
              </ul>
            </div>
            <footer>
              <ul className="tech-list"></ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <div className="project-links"></div>
            </header>
            <div className="body">
              <h3>Advanced</h3>
              <p>Boost your business with a smart way to increase sales </p>
            </div>
            <svg
              width="100px"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              viewBox="0 10 50 24"
              fill="none"
              stroke="#d5d2f1 "
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <title>Folder</title>
              <path d="M23 13H2v-2h21v2z"></path>
            </svg>
            <h3>from £499</h3>
            <ul>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                Up to 5 Pages of Content
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                Website Design & Build
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                Search Engine Optimisation
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                1st year of Fast UK Hosting
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                Custom Domain Name
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                2 Custom Email Address*
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                SSL Certificate
              </li>
            </ul>
            <footer>
              <ul className="tech-list"></ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header></header>
            <div className="body">
              <h3>Pro</h3>
              <p>Take full control of your businesses web presence</p>
            </div>
            <svg
              width="100px"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              viewBox="0 10 50 24"
              fill="none"
              stroke="#c2f4fb "
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <title>Folder</title>
              <path d="M23 13H2v-2h21v2z"></path>
            </svg>
            <h3>from £799</h3>
            <path
              d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"
              id="Path"
              stroke="#0C0310"
              stroke-width="2"
              stroke-linecap="round"
            ></path>
            <ul>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                Up to 10 Pages of Content
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                Website Design & Build
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                Search Engine Optimisation
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                1st year of Fast UK Hosting
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                Custom Domain Name
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                5 Custom Email Address*
              </li>
              <li>
                <svg
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 -7 24 24"
                  fill="none"
                  stroke="#23ce6b "
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <title>Folder</title>
                  <path d="M8.5,12.5 L10.151,14.5638 C10.3372,14.7965 10.6843,14.8157 10.895,14.605 L15.5,10"></path>
                </svg>
                SSL Certificate
              </li>
            </ul>
            <footer>
              <ul className="tech-list"></ul>
            </footer>
          </div>
        </ScrollAnimation>
      </div>
      <ScrollAnimation animateIn="flipInX">
        <div className="project_width">
          <header>
            <div className="project-links"></div>
          </header>
          <div className="body">
            <h3>Need something else?</h3>
            <p>
              Are you looking to generate sales, strengthen your brand, increase
              awareness or achieve any other goal? <br />
              Through a process of research, planning, design, development and
              marketing,
              <br />
              our team do everything to ensure you achieve the goals you want.{" "}
              <br />
              It's not just websites we do here, get in touch with us today!
            </p>
            <h6>*For an additional monthly charge</h6>
          </div>
          <footer>
            <ul className="tech-list"></ul>
          </footer>
        </div>
      </ScrollAnimation>
    </Container>
  );
}
