import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { GlobalStyle } from './styles/global'
import { Header } from './components/Header/Header'
import { Main } from './components/Main/Main'
import { Policy } from './components/Policy/Policy'
import { Footer } from './components/Footer/Footer'

import 'react-toastify/dist/ReactToastify.css'
import { createBrowserHistory } from "history";
function App() {

var openMenu = ""
const history = createBrowserHistory()
// console.log("history.location.pathname: " + history.location.pathname);
if (history.location.pathname === "/") {
   openMenu = ""
} else if (history.location.pathname === "/policy") {
   openMenu = "policy"
}
function showHomePage() {
   return (    <>
                           <GlobalStyle></GlobalStyle>
                           <Header></Header>
                           <Main></Main>
                           <Footer></Footer>
                           <Router>
                            <Routes>
                              <Route path="/policy" element={<Policy />} />
                            </Routes>
                           </Router>
                         </>)
}
function showAboutPage() {
   return (    <>
                <Policy></Policy>
               </>)
}
  return (
             <div>
               {openMenu ? (
                 showAboutPage()
               ) : (
                 showHomePage()
               )}
             </div>
           );
}

export default App