import { GlobalStyle } from '../../styles/global'
import { Container } from "./styles";
import { HeaderExternal } from '../../components/HeaderExternal/HeaderExternal'
import { Footer } from '../../components/Footer/Footer'

export function Policy() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <HeaderExternal></HeaderExternal>
      <Container id="policy">
            <h2>Privacy Policy</h2>
           <div className="project_width">
                  <div className="body">
                              <p>
                                Web Flames respects your privacy and is committed to protecting your personal data. During the enquiry and payment process Web Flames   users to give us contact information – e.g. name, company, telephone and e-mail address.  We may also collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and services, when you participate in activities on the website or otherwise when you contact us. At no point will this information be disclosed to any third-party or in any way released without your express permission. Any information collected will only be used in accordance with this privacy policy.<br />

                                This privacy policy applies to all information collected through our services which includes our website, as well as any related services, branding, logins or any personal information regarding your company.<br />

                                Personal Information Held by<br />
                                Web Flames<br />

                                We may collect the following information:<br />
                                Name, company, What your company does, Contact information including email address and telephone number<br />
                                Demographic information, target audience, preferences and interests<br />
                                Other information relevant to customer surveys and/or offers<br />

                                In order to enhance our ability to provide relevant Web development, offers and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, social media platforms, as well as from other third parties. This information includes mailing addresses, email addresses, phone numbers, intent data (or user behaviour data), Internet Protocol (IP) addresses, social media profiles, social media URLs and custom profiles, for purposes of targeted advertising and main focus of your website If you interact with us on a social media platform using your social media account (e.g. Facebook or Instagram), we receive personal information about you such as your name, email  address and company name what you’d want us to work on. Any personal information that we collect from your social media account depends on your social media account's privacy settings.<br />

                                We require this information to understand your needs, provide the perfect website and provide you with a better service and in particular for the following reasons:<br />
                                Internal record keeping<br />
                                Creation of a personalised service that is right for you<br />
                                We may use the information to improve our products and services<br />
                                We may periodically send promotional email about   special offers or other information which we think you may find interesting using the email address which you have provided<br />
                                From time to time, we may also use your information to contact you for market research purposes. We may contact you by email or phone<br />
                                We may use the information to customise our products according to your interests and share it on over platform the work we’ve done for you via our website or social media.<br />

                                We will not sell, distribute or lease your personal information to third parties. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.<br />

                                You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information.<br />

                                If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.<br />

                                How we use cookies<br />

                                A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.<br />

                                We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the a cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.<br />

                                You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.<br />

                                Our website may contain links to enable you to visit other websites of interest easily. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.<br />

                                Opting Out<br />

                                Individuals may opt-out of receiving future marketing e-mailings from Web flames. To remove your name from our e-mail list or database.<br />

                                Photographs and Video Footage in Marketing Material<br />

                                With the Client’s approval we use photographs and video content taken at our events in our marketing material in accordance with our legitimate interests. Any individual can decline to have their image used in such a way and may ask at any future time for their image be removed from any such material.<br />

                                For More Information<br />

                                If you have any questions or comments about this policy please contact us at: <a href="help.webflame@gmail.com">help.webflame@gmail.com</a>
                              </p>
                            </div>
                  </div>
                      </Container>

      <Footer></Footer>
    </>
  );
}