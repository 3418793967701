import { Container } from "./styles";
import whatsapp from "../../assets/whatsapp.svg";
import instagramIcon from "../../assets/instagram.svg";

export function Footer() {
  return (
    <Container className="footer">
      <div style={{ fontSize: 12 + "px", color: "white" }}>
        <p>
          © Copyright 2024 |
          <a href="https://webflame.co.uk" style={{ color: "inherit" }}>
            www.webflame.co.uk |
          </a>
          <a href="/policy" style={{ color: "inherit" }}>
            Privacy Policy
          </a>
        </p>
      </div>
      <div className="social-media">
        <a
          href="https://www.instagram.com/itswebflame/?igsh=MTFlenQxbTdvNjM4eA%3D%3D"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagramIcon} alt="Instagram" />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=+447763293061&text=Hello+WebFlame, "
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="Whatsapp" />
        </a>
      </div>
    </Container>
  );
}
