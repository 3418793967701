import { Container, ContainerSuccess } from './styles'
import { useForm, ValidationError } from '@formspree/react'
import { toast, ToastContainer } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'
import { useEffect, useState } from 'react'
import validator from 'validator'
export function Form() {
  const [state, handleSubmit] = useForm('xrgnoagg')
  const [validEmail, setValidEmail] = useState(false)
  const [isHuman, setIsHuman] = useState(false)
  const [message, setMessage] = useState('')
  function verifyEmail(email: string) {
    if (validator.isEmail(email)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }
  useEffect(() => {
    if (state.succeeded) {
      toast.success('Email successfully sent!', {
//         position: toast.POSITION.TOP_RIGHT,
        pauseOnFocusLoss: false,
        closeOnClick: true,
        hideProgressBar: false,
        toastId: 'succeeded',
      })
    }
  })
  if (state.succeeded) {
      return (
        <ContainerSuccess>
          <h3>Thanks for getting in touch!</h3>
          <button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          >
            Back to the top
          </button>
          <ToastContainer className="toast-position"/>
        </ContainerSuccess>
      )
    }
 return (
     <Container>
       <h2>Get in touch using the form</h2>
       <form onSubmit={handleSubmit}>
         <input
           placeholder="Email"
           id="email"
           type="email"
           name="email"
           onChange={(e) => {
             verifyEmail(e.target.value)
           }}
           required
         />
         <ValidationError prefix="Email" field="email" errors={state.errors} />
         <textarea
           required
           placeholder="Send a message to get started."
           id="message"
           name="message"
           onChange={(e) => {
             setMessage(e.target.value)
           }}
         />
         <ValidationError
           prefix="Message"
           field="message"
           errors={state.errors}
         />
         <ReCAPTCHA
           sitekey="6LdFG7UpAAAAAPPwu2LqW2_0mr1kclkTh8fQwLEY"
           onChange={(e) => {
             setIsHuman(true)
           }}
         ></ReCAPTCHA>
         <button
           className="submit-button"
           type="submit"
           disabled={state.submitting || !validEmail || !message || !isHuman}
         >
           Submit
         </button>
       </form>
       <ToastContainer className="toast-position"/>
     </Container>
   )
 }